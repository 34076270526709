import { downloadConfig, apiHost } from '@/config/setup';
import siteConfig from '@/config/site';
import { getOriginPath } from '@/lang/i18n';
import { getCookie } from '@/utils/cookie';
import { toast } from '@/utils/toast';
import { isClient } from '@vueuse/shared';
import http from './axios';
import { useUserStore } from '@/store/User';
import { ucApi } from '@/config/api';
import { times, toFixed, toFormat, toFormatUnit } from './number';
// vue-clipboard3 https://github.com/JamieCurnow/vue-clipboard3/issues/10
// import useClipboard from 'vue-clipboard3';
// import useClipboard from 'vue-clipboard3/dist/esm/index';
// import ultralightCopy from 'copy-to-clipbord-ultralight';

import { i18n } from '@/lang/i18n';
// import Vconsole from 'vconsole';
// new Vconsole();
/**
 * 法币估值
 * 计算公式= rates[token][moneys[choose][1]]*value
 * @param {object} rates 所有汇率 { BTC:{ BTC:1, CNY: 4000, USD: 3000} }
 * @param {number} value token的值, 如 33.2
 * @param {string} token tokenId, 如BTC，ETH
 * @param {string} choose 转换成何种法币，如 en-us, zh-cn， 默认en-us
 * @param {bool} suffix 是否返回后货币符号，默认false返回如[¥, 100]，为true时返回如[CNY, 100]
 * @return {array} [法币标志,法币估值], 如 ['usd',2323.231] , 法币估值保留2位小数，如果小于0.01，保留5位小数, 如果估值为负数, value返回'--';
 */
export const currencyValue = (
  rates,
  value,
  token,
  choose = isClient ? (window.localStorage.legal_flag || '') : '',
  suffix = false
) => {
  const money = siteConfig.supportLanguages;
  if (
    !rates ||
    !money ||
    !money.length ||
    (!value && value !== 0) ||
    !token ||
    !rates[token]
  ) {
    return suffix ? ['', '0.00', ''] : ['', '0.00'];
  }

  let moneys = {};
  money.map(item => {
    moneys[item.suffix.toLowerCase()] = [item.prefix, item.suffix];
  });
  // 要获取的法币是否有汇率，如果没有，默认获取en-us
  const realChoose =
    moneys[choose.toLowerCase()] && moneys[choose.toLowerCase()][0]
      ? choose.toLowerCase()
      : 'usd';

  if (!moneys[realChoose]) {
    // 折算不出来都返成0.00
    return suffix ? ['', '0.00', ''] : ['', '0.00'];
  }

  const name = moneys[realChoose][0];
  const endName = moneys[realChoose][1];
  let v = rates[token.toUpperCase()][moneys[realChoose][1]];
  // 选择币对的汇率不存在
  if (!v) {
    return suffix ? [name, '0.00', endName] : [name, '0.00'];
  }

  v = times(v, value);
  let fix = v > 0 && v - 0.1 < 0 && Number(v) !== 0 ? 5 : 2;
  if (v > 0) {
    if (v - 0.00001 < 0) {
      fix = 8;
    }
    if (v - 0.00000001 < 0) {
      fix = 10;
    }
    if (v - 0.0000000001 < 0) {
      fix = 12;
    }
    // 5997，- 对于最新估值结果>=1的，都统一显示小数点后2位，截断展示；- 对于最新估值结果<1的，如最后估值是“0.00003456”，则展示2位有效数字（有效数字指从左边第一位开始到末尾不为0的数字），截断展示。如：“0.000034”
    if (v >= 1) {
      fix = 2;
    } else {
      let strArray = (String(v).split('.')[1] + '').split('');
      let index = 0;
      for (let i = 0; i < strArray.length; i++) {
        if (strArray[i] != '0') {
          index = i;
          break;
        }
      }
      if ((strArray.length - 1) - index > 1) {
        fix = index + 2;
      } else {
        fix = strArray.length;
      }
    }
    if (fix > 12) fix = 12; // - 限定最长小数点后12位
  }
  v = toFixed(v, fix);

  // if (Number(v) < 0) {
  //   v = '--';
  // } else {
  //   v = toFormat(v, fix);
  // }

  v = toFormat(v, fix);

  return suffix ? [name, v, endName] : [name, v];
};

/**
 * 估值单位转换 比如将USDT转化为BTC 用来处理极端情况下，btc估值为0，但是usdt估值不为0的情况
 * 会损失一定的精度
 * @param {object} rates 所有汇率 { BTC:{ BTC:1, CNY: 4000, USD: 3000} }
 * @param {number} value token的值, 如 33.2
 * @param {string} sourceToken 转换前tokenId, 如BTC，ETH
 * @param {string} targetToken 转换后tokenId, 如BTC，ETH
 */
export const convertValuationUnit = (rates, value, sourceToken, targetToken) => {
  let convertRate = rates[sourceToken][targetToken];
  const v = times(value, convertRate);

  return v;
};

/**
 *
 * @param {string} value 值
 * @param {boolean} isDecimal 是否有小数
 * @param {number | string} accuracy 小数精度
 * @param {number | string} integer 数据位数
 * @returns
 */
export const filterNumberAndDot = (value, isDecimal = false, accuracy = 0, integer = 0) => {
  if (!value) {
    return '';
  }

  value = value.replace('。', '.');

  if (value.length >= 2) {
    const n = value.substring(0, 1);
    const i = value.substring(1, 2);

    if (n === '0' && i !== '.') {
      return '0';
    }
  }

  // if(accuracy === 0) isDecimal = false
  // 没有小数
  if (!isDecimal) {
    value = value.replace(/[^\d]/g, '');

    if (integer) {
      return value.length > integer ? value.slice(0, integer) : value;
    }

    return value;
  }

  // 没有点，或点在第一位
  if (value.indexOf('.') === -1 || value.indexOf('.') === 0) {
    value = value.replace(/[^\d]/g, '');

    if (integer) {
      return value.length > integer ? value.slice(0, integer) : value;
    }

    return value;
  }

  value = value
    .replace(/[^\d.]/g, '') // 将非数字和点以外的字符替换成空
    .replace(/^\./g, '') // 验证第一个字符是数字而不是点
    .replace(/\.{2,}/g, '.') // 出现多个点时只保留第一个
    .replace('.', '$#$') // 1、将数字的点替换成复杂字符$#$
    .replace(/\./g, '') // 2、将字符串的点直接清掉
    .replace('$#$', '.'); // 3、将复杂字符再转换回点

  const valueArr = value.split('.');

  if (integer) {
    valueArr[0] = valueArr[0].length > integer ? valueArr[0].slice(0, integer) : valueArr[0];
  }

  if (valueArr[1].length <= Number(accuracy)) {
    return valueArr.join('.');
  }

  valueArr[1] = valueArr[1].slice(0, Number(accuracy));

  return valueArr.join('.');
};

/**
 * 验证码数字
 * @param {string} value 值
 * @returns
 */
export const isNumber = value => {
  value = value.replace(/[^\d]/g, '');
  return value;
};

/**
 * 替换中文为空串
 * @param {string} value 值
 * @returns
 */
export const filterChinese = value => {
  value = value.replace(/[\u4e00-\u9fa5]/g, '');
  return value;
};

/**
 * 替换非中文为空串
 * @param {string} value 值
 * @returns
 */
export const onlyChinese = value => {
  value = value.replace(/[^\u4e00-\u9fa5]/g, '');
  return value;
};

/**
 * 浮点类型转整型精度
 * @param {*} float
 */
export const floatToPrecisionInt = floatString => {
  if (!floatString) {
    return 0;
  }

  const strArray = String(floatString).split('.');

  return strArray.length > 1 ? strArray[1].length : 0;
};

/**
 * 获取交易对精度
 *
 * @param {*} symbol 交易对信息
 * @returns Array
 */
export const getSymbolPrecision = symbol => {
  const { digitMerge = '' } = symbol;
  const options = digitMerge.split(',').map(item => {
    const strArray = item.split('.');
    const intLength = strArray[0].length - 1;
    const intValue = intLength === 0 ? 0 : -intLength;

    return {
      label: item,
      value: strArray.length > 1 ? strArray[1].length : intValue
    };
  });

  // 从小到大排序
  return options.sort((a, b) => a.value > b.value ? -1 : 1);
};

/**
 * 数量加单位（K,M,B）、处理精度
 * 目前是超过4位加单位
 *
 * @param {string} amount 数量
 * @param {number} precision 精度
 * @param {number} length 加单位触发位数
 */
export const amountFormat = (amount = '', precision, unitLength = 6) => {
  // TODO 可以更严格点，先校验下是否为数字

  if (String(amount).split('.')[0].length > unitLength) {
    return toFormatUnit(amount, precision != 0 ? precision : 2, 0).replace(' ', '');
  } else {
    return toFormat(amount, precision, 0);
  }
};

/**
 * 简短地址
 * @param {string} address 地址字符串
 * @returns {string} 格式化后的字符串，例如：0x...abcdef
 */
export const shortAddress = address => {
  return address.replace(/(.{4}).+(.{6})$/, '$1...$2');
};

/**
 * 简短账户号
 * @param {string} accountId 地址字符串
 * @returns {string} 格式化后的字符串，例如：0x...abcdef
 */
export const shortAccountId = accountId => {
  return accountId.replace(/(.{3}).+(.{4})$/, '$1...$2');
};

// 尺寸变化事件
export const resizeObserver = cb => new ResizeObserver(cb);

/**
 *
 * @param {*} scene 使用场景
 */
export const generateCaptcha = async scene => {
  // 没有传场景
  if (!scene) {
    return false;
  }

  const sceneArr = [
    'login', // 登录
    'register', // 注册
    'reset_password', // 忘记密码
    'rebind_email', // 换绑邮箱
    'rebind_mobile' // 换绑手机
  ];

  let token = '';

  // 不在当前场景内
  if (!sceneArr.includes(scene)) {
    return false;
  }

  if (!grecaptcha) {
    return false;
  }

  token = await grecaptcha.enterprise.execute('6LenB_QiAAAAAOLAvSvN_2mZAb6ZOwYa5mwTCWk2', { action: scene });

  return token;
};

/**
 * bex颜色转为rgba
 * @param String #ff00ff #f0f
 * @param Number a 0-1
 * @return String rgba(r,g,b,a)
 */
export const hexToRgba = (hex, a = 1) => {
  if (!hex || hex.indexOf('#') == -1) {
    return 'rgba(0,0,0,0)';
  }
  if (hex.length != 7 && hex.length != 4) {
    console.error(`${hex} is not hex color`);
    return 'rgba(0,0,0,0)';
  }
  let s = hex.replace('#', '').match(/^(..?)(..?)(..?)/);
  return `rgba(${parseInt(
    '0x' + s[1] + (s[1].length == 1 ? s[1] : '')
  )},${parseInt('0x' + s[2] + (s[2].length == 1 ? s[2] : ''))},${parseInt(
    '0x' + s[3] + (s[3].length == 1 ? s[3] : '')
  )},${Number(a)})`;
};

/**
 * 数组排重
 * @param key 根据key进行排重
 * @param ar  数组
 * @param time 如果重复，根据time进行保留最新
 */
export const excludeRepeatArray = (key, ar, time) => {
  let obj = {};
  if (
    !key ||
    !ar ||
    Object.prototype.toString.call(ar) !== '[object Array]'
  ) {
    return;
  }
  ar.forEach(item => {
    if (obj[item[key]]) {
      // 如果重复，保留time最新的数据
      if (time && item[time] - obj[item[key]][time] >= 0) {
        obj[item[key]] = item;
      }
    } else {
      obj[item[key]] = item;
    }
  });
  const result = [];
  for (let k in obj) {
    result.push(obj[k]);
  }
  return result;
};

export const delay = timeout => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
};

export const isMobile = () => {
  return window.screen.availWidth < 800;
};

export const isApp = () => {
  return !!location.href.match('h5');
};

export const isPC = () => {
  let userAgentInfo = window.navigator.userAgent,
    Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod', 'rv:1.2.3.4', 'windows mobile', 'midp'],
    flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) >= 0) {
      flag = false;
      break;
    }
  }
  return flag;
};
// 是否是安卓
export const isAndroid = () => {
  return /android/i.test(navigator.userAgent);
};

// 是否是ios
export const isIOS = () => {
  return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
};

// 是否是在微信内打开
export const isWeChat = () => {
  var ua = window.navigator.userAgent.toLowerCase();
  // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
};

// 20221201 =>  2022-12-01
export const sliceTime = time => {
  if (time) {
    return `${time.slice(0, 4)}-${time.slice(4, 6)}-${time.slice(6, 8)}`;
  }
  return time;
};
export const showAssets = assets => {
  let index = assets.indexOf('(');
  if (index > -1) {
    return assets.slice(index + 1, -1);
  }
  return assets;
};

export const countDown = (end, now) => {
  const msec = end - now;
  let day = parseInt(msec / 1000 / 60 / 60 / 24);
  let hr = parseInt(msec / 1000 / 60 / 60 % 24);
  let min = parseInt(msec / 1000 / 60 % 60);
  let sec = parseInt(msec / 1000 % 60);
  hr = hr > 9 ? hr : '0' + hr;
  min = min > 9 ? min : '0' + min;
  sec = sec > 9 ? sec : '0' + sec;
  if (msec === 1000) {
    return '00:00:00';
  }
  return `${day > 0 ? day + ':' : ''}${hr}:${min}:${sec}`;
};

export const gaTag = (value, data = {}) => {
  if (!value) return;
  window && window.gtag && window.gtag('event', value, data);
};

export const fbq = (value, data = {}) => {
  if (!value) return;
  window && window.fbq && window.fbq('track', value, data);
};
// app合约交易
export const toContractTransaction = symbolId => {
  if (!isApp()) return;
  window.JumpFlutter.postMessage(`hashkey://www.hashkey.com/contract?symbolId=${symbolId}`);
};
// app首页
export const toAppHome = () => {
  if (!isApp()) return;
  window.JumpFlutter.postMessage('hashkey://www.hashkey.com/home');
};
// app现货交易
export const toSpotTransaction = () => {
  if (!isApp()) return;
  window.flutter_inappwebview.callHandler('JumpFlutter', {
    url: 'hashkey://www.hashkey.com/spot'
  });
};
// app KYC
export const toAppKYC = () => {
  if (!isApp()) return;
  window.flutter_inappwebview.callHandler('JumpFlutter', {
    url: 'hashkey://www.hashkey.com/kyc_page',
    needReload: true
  });
  // window.JumpFlutter.postMessage('hashkey://www.hashkey.com/kyc_page');
};
// 调用app登录
export const toAppLogin = () => {
  if (!isApp()) return;
  window.flutter_inappwebview.callHandler('JumpFlutter', {
    url: 'hashkey://www.hashkey.com/login',
    needReload: true
  });
  // window.flutter_inappwebview('hashkey://www.hashkey.com/login');
};
// 调用app注册
export const toAppRegister = () => {
  if (!isApp()) return;
  window.JumpFlutter.postMessage('hashkey://www.hashkey.com/register');
};
// 不传 tab 时，默认跳转到历史记录页面，传tab时，action=time => 解锁计划->按时间；action=activity => 解锁计划->按活动
// 调用app hsk积分页面
export const toHSKScorePage = (action = '') => {
  if (!isApp()) return;
  if (action) {
    window.flutter_inappwebview.callHandler('JumpFlutter', {
      url: `hashkey://www.hashkey.com/hsk_score_page?tab=${action}`
    });
  } else {
    window.flutter_inappwebview.callHandler('JumpFlutter', {
      url: 'hashkey://www.hashkey.com/hsk_score_page'
    });
  }
  // window.JumpFlutter.postMessage('hashkey://www.hashkey.com/hsk_score_page');
};
// 调用 App 跳转 HSK 兑换 / 划转页面
export const toHSKConvertOrTransferPage = (action = '') => {
  if (!isApp()) return;
  if (action === 'convert') {
    window.flutter_inappwebview.callHandler('JumpFlutter', {
      url: 'hashkey://www.hashkey.com/hsk_token_convert_page'
    });
  } else {
    window.flutter_inappwebview.callHandler('JumpFlutter', {
      url: 'hashkey://www.hashkey.com/hsk_transfer_page'
    });
  }
};
// 调用app分享
export const appShare = image => {
  if (!isApp()) return;
  console.log('share');
  window.JumpFlutter.postMessage(`hashkey://www.hashkey.com/share?image=${image}`);
};

export const newAppShare = image => {
  if (!isApp()) return;
  console.log('new share');
  window.flutter_inappwebview.callHandler('JumpFlutter', {
    url: `hashkey://www.hashkey.com/share?image=${image}`
  }).then(() => {
    console.log('share success');
  });
};
// 调用app跟单
export const appCopyTrading = () => {
  if (!isApp()) return;
  window.JumpFlutter.postMessage('hashkey://www.hashkey.com/copytrading');
};
// 跳转app充值
export const appDeposit = (coin = 'USDT') => {
  if (!isApp()) return;
  window.JumpFlutter.postMessage(`hashkey://www.hashkey.com/deposit?tokenId=${coin.toLocaleUpperCase()}`);
};
// 调用app关闭h5
export const appCloseH5 = () => {
  if (!isApp()) return;
  window.JumpFlutter.postMessage('hashkey://www.hashkey.com/close');
};
// app登录回调
export const isAppLogin = () => {
  window.isAppLogin = message => {
    if (message) {
      window.location.reload();
    }
  };
};
export const getBackUrl = () => {
  return `/login?backurl=${encodeURIComponent(getOriginPath(window.location.pathname))}${encodeURIComponent(window.location.search)}`;
};
// app粘贴
export const appCopy = text => {
  if (!isApp()) return;
  window.JumpFlutter.postMessage(`hashkey://www.hashkey.com/copy?text=${text}`);
};

export const newAppCopy = text => {
  if (!isApp()) return;
  window.flutter_inappwebview.callHandler('JumpFlutter', {
    url: `hashkey://www.hashkey.com/copy?text=${text}`
  }).then(() => {
    console.log('share success');
  });
};

export const leftPad = num => {
  return num < 10 ? '0' + num : '' + num;
};
// 毫秒 时间戳转化时间  tpl 控制格式
export const dateFormatter = (ts, tpl = 'Y-M-D H:m:s', utc = false) => {
  if (!ts) {
    return '--';
  }
  const date = new Date(Number(ts));
  const dateObj = utc
    ? {
      Y: date.getUTCFullYear(),
      M: leftPad(date.getUTCMonth() + 1),
      D: leftPad(date.getUTCDate()),
      H: leftPad(date.getUTCHours()),
      m: leftPad(date.getUTCMinutes()),
      s: leftPad(date.getUTCSeconds())
    }
    : {
      Y: date.getFullYear(),
      M: leftPad(date.getMonth() + 1),
      D: leftPad(date.getDate()),
      H: leftPad(date.getHours()),
      m: leftPad(date.getMinutes()),
      s: leftPad(date.getSeconds())
    };
  for (var key in dateObj) {
    tpl = tpl.replace(key, dateObj[key]);
  }
  return tpl;
};
// 秒转时间
export const unixFormatter = (unix, tpl) => {
  return dateFormatter(unix * 1000, tpl);
};

/**
 * 外链图片url转base64
 * @param {*} url
 * @returns
 */
export const getURLBase64 = url => {
  return new Promise(async resolve => {
    const res = await http.get(url, { withCredentials: false, responseType: 'blob' });
    if (res.success) {
      const imgSrc = URL.createObjectURL(res.data);
      resolve(imgSrc);
    } else {
      resolve('');
    }
  });
};

// APP登录状态写入Cookie
export const appLoginCookie = () => {
  let cookieDomain = 'hashkeydev.com';
  if (isApp()) {
    if (getCookie('au_token')) {
      document.cookie = `account_id=${getCookie('account_id')};path=/;domain=${cookieDomain}`;
      document.cookie = `user_id=${getCookie('user_id')};path=/;domain=${cookieDomain}`;
      document.cookie = `c_token=${getCookie('c_token')};path=/;domain=${cookieDomain}`;
      document.cookie = `au_token=${getCookie('au_token')};path=/;domain=${cookieDomain}`;
    }
  }
};

// 动态添加js库
export const loadScript = (url, callback, errorFuc) => {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  if (script.readyState) { // IE
    script.onreadystatechange = () => {
      if (script.readyState == 'loaded' || script.readyState == 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else { // Others
    script.onload = () => {
      callback();
    };
  }
  script.onerror = () => {
    errorFuc();
  };
  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

/**
 * @param {pathname} pathname 打开App应用中的功能页面，如登录，注册等
 *
*/
export const toAppPage = pathname => {
  window.flutter_inappwebview.callHandler('JumpFlutter', {
    url: `hashkey://www.hashkey.com${pathname}`,
    needReload: true
  });
};

/**
 * @param {pathname} pathname 打开App应用中打开活动页面，如/activity/mbox
 *
*/
export const testH5ToApp = pathname => {
  const url = downloadConfig.webviewToH5(pathname);
  // window.flutter_inappwebview.callHandler('JumpFlutter', {
  //   url: url,
  //   needReload: true
  // });
  window.location.href = url;
};

/**
 * @param {pathname} pathname 需要在app中打开的H5的pathname
 * 微信中打开提示去浏览器中打开
 * H5检测APP，如果3秒打不开app应用，跳转到下载页
 *
*/
export const testApp = pathname => {
  const isWeChat = navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1;

  // pathname = '/h5/activity/visitpc';

  if (isWeChat) {
    let isZhCN = location.pathname.includes('zh-CN');
    let div = document.createElement('div');
    div.className = `wechat-tip ${isZhCN ? 'zh' : 'en'}`;
    document.body.appendChild(div);
  } else if (isAndroid()) {
    window.location.href = downloadConfig.webview(pathname);
    window.setTimeout(() => {
      window.location.href = downloadConfig.androidUrl;
    }, downloadConfig.duration);
  } else if (isIOS()) {
    window.location.href = downloadConfig.webview(pathname);
    window.setTimeout(() => {
      window.location.href = downloadConfig.iOSUrl;
    }, downloadConfig.duration);
  }
};

// 日期转换 比如1990-1-1转成1990-01-01
export const updateDate = time => {
  const list = time.split('-');
  const year = list[0];
  const month = list[1] < 10 && list[1].length < 2 ? `0${list[1]}` : list[1];
  const day = list[2] < 10 && list[1].length < 2 ? `0${list[2]}` : list[2];
  return year + '-' + month + '-' + day;
};

/**
 * @param {msg} msg 需要copy的字符串
 * 由于原来的copy在安卓机，app里面copy会失效，换成新的copy插件
 *
*/
export const useCopy = async msg => {
  let oInput = document.createElement('input');
  oInput.readOnly = 'readOnly';// 防止ios触发键盘事件
  // 将想要复制的值
  oInput.value = msg;
  // 页面底部追加输入框
  document.body.appendChild(oInput);
  // 选中输入框
  oInput.select();
  // 执行浏览器复制命令
  document.execCommand('Copy');
  // 弹出复制成功信息
  toast.success(i18n.global.t('复制成功'));
  // 复制后移除输入框
  oInput.remove();

};

// 排序 升序
export const sortKey = (array, key) => {
  return array.sort(function(a, b) {
    var x = a[key];
    var y = b[key];
    return ((x < y) ? -1 : (x > y) ? 1 : 0);
  });
};
/**
 * 简短file name
 * @param {string} fileName 地址字符串
 * @returns {string} 格式化后的字符串，例如：0x...abcdef
 */
export const shortFileName = fileName => {
  if (fileName.length > 20) {
    return fileName.replace(/(.{4}).+(.{6})$/, '$1...$2');
  } else {
    return fileName;
  }

};

// jwt前端解析
export const formatJWTToken = token => {
  let strings = token.split('.'); // 截取token，获取载体
  if (strings.length > 1) {
    return JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))))); // 解析，需要吧‘_’,'-'进行转换否则会无法解析
  } else {
    return token;
  }
};
// M站香港站国际站站点之前跳转
export const linktoMGlobalSite = async => {
  window.location.href = apiHost.webGB;
};
// 香港站国际站站点之间跳转
const userStore = useUserStore();
export const linkToGlobalSite = async extraQuery => {
  // 目标host
  let target = apiHost.webGB;
  if (location.origin == apiHost.webHKTest) {
    // 本地调试
    target = apiHost.webGBTest;
  }
  if (!userStore.isLogin) {
    // 未登录态、直接跳转首页
    let urlQuery = new URLSearchParams({ ...extraQuery, passport: 'global' }).toString();
    window.location.href = `${target}?${urlQuery}`;
  } else {
    // 先去国际站验证是否已登录，非登录再去passport
    let urlQuery = new URLSearchParams({ fromWebSite: 'hk' }).toString();
    window.location.href = `${target}/auth?${urlQuery}`;
  }
};
// 跳转passport
export const linkToPassport = fullPath => {
  // 目标host
  let target = apiHost.webPP;
  if (location.origin == apiHost.webHKTest) {
    // 本地调试
    target = apiHost.webPPTest;
  }
  window.location.href = `${target}${fullPath}`; ;
};
// 跳转exchange
export const linkToExchange = (path, query) => {
  const queryStr = new URLSearchParams(query).toString();
  const url = `${apiHost.web}${path}${queryStr ? `?${queryStr}` : ''}`;
  window.location.href = url;
};

// jsonp调用
export const axiosJsonp = (url, data) => {
  const callback = 'CALLBACK' + Math.random().toString().substr(9, 18);
  const JSONP = document.createElement('script');
  JSONP.setAttribute('type', 'text/javascript');
  const headEle = document.getElementsByTagName('head')[0];
  let ret = '';
  if (data) {
    if (typeof data === 'string') {
      ret = '&' + data;
    } else if (typeof data === 'object') {
      for (let key in data) {
        ret += '&' + key + '=' + encodeURIComponent(data[key]);
      }
    }
    ret += '&_time=' + Date.now();
  }
  JSONP.src = `${url}?callback=${callback}${ret}`;
  return new Promise((resolve, reject) => {
    window[callback] = r => {
      resolve(r);
      headEle.removeChild(JSONP);
      delete window[callback];
    };
    headEle.appendChild(JSONP);
  });
};

/**
 * 获取flutter h5的链接前缀
 */

export function getFlutterH5Prefix() {
  const env = import.meta.env.MODE || '';
  const flutterH5HostPROD = 'https://app-h5-pro.hashkey.com/';
  let fluterH5Url = '';
  if (typeof env == 'string' && (env.includes('dev') || env.includes('qa') || env.includes('stg'))) {
    fluterH5Url = `https://app-h5-pro.${env}.hashkeydev.com/`;
  } else {
    fluterH5Url = flutterH5HostPROD;
  }
  return fluterH5Url;
}

// 联系客服调zendesk对话框
export const contractUs = () => {
  if (window.zE) {
    // const lang = zendesk.locale[locale.value] || locale.value.toLowerCase();
    // // 设置 zendesk 语言
    // window.zE('messenger:set', 'locale', lang);
    // // 点击zendesk按钮打开对话框埋点
    // window.zE('messenger:on', 'open', () => {
    //   sendTrack('web_app_view_home_cschat');
    // });
    window.zE('messenger', 'open');
  } else {
    setTimeout(contractUs, 100);
  }
};

// 获取字符串小数点位数
export const getDecimalPlaces = value => {
  if (!value || typeof value !== 'string' || isNaN(Number(value))) {
    return 0;
  }
  if (value.includes('.')) {
    const len = value.toString()?.split('.')[1].length;
    return len < 2 ? 2 : len;
  } else {
    return 2;
  }
};

// 通过打包命令获取当前环境 exchange或者www
export const getHostEnv = () => {
  try {
    console.log(__HOST_ENV__);
    return __HOST_ENV__ === 'hk' ? 'exchange' : 'www';
  } catch (error) {
    return 'www';
  }
};

// 是否hk站
export const isHK = () => {
  try {
    return __HOST_ENV__ === 'hk';
  } catch (error) {
    return false;
  }
};
