// eslint-disable-next-line
// @ts-nocheck
import { getOriginPath, i18n } from '@/lang/i18n';
import { apiHost } from '@/config/setup';
import { toast } from '@/utils/toast';
import { getCookie, removeCookie } from '@/utils/cookie';
import { pinia } from '@/store';
import { useUserStore } from '@/store/User';
import { linkToPassport, isPC, isHK, linkToExchange } from '@/utils/tools';

const userStore = useUserStore(pinia);

// 子账户限制路由
const forbiddenSubAccountRoute = ['/invite', '/user/kyc', '/user/sub-account', '/withdrawal',  /^\/withdrawal\/[a-zA-Z0-9]{1,20}$/];
/**
 * 是否禁止子账户进入
 *
 * @param path 路由path
 * @returns
 */
const isForbidenSubAccount = (path: string) => {
  return forbiddenSubAccountRoute.some((item: any) => {
    const originPath = getOriginPath(path);
    if (item instanceof RegExp) {
      return item.test(originPath);
    } else {
      return item === originPath;
    }
  });
};

export default {
  beforeEach: [(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext):void => {
    const { fullPath, path, meta, query } = to;
    const { auth } = meta;
    const token = getCookie('c_token');
    const userId = getCookie('user_id');
    const accountId = getCookie('account_id');
    // 禁止登录状态访问的页面 (国际站登录，注册，忘记密码走 passport)
    const forbiddenRoute = ['/login', '/reset-password', '/register', '/login/', '/reset-password/', '/register/'];
    // backurl进行encode
    let urlQuery = new URLSearchParams({ ...query, passport: 'hk' }).toString();
    if (query?.backurl) {
      urlQuery = new URLSearchParams({ ...query, backurl: encodeURIComponent(decodeURIComponent(query?.backurl)), passport: 'hk' }).toString();
    }

    // 禁止登录态访问登录页
    const filterLoginPage = () => {
      if (forbiddenRoute.includes(getOriginPath(path))) {
        next({ path: '/' });
      }
    };

    // 子账户跳转限制
    const filterSubAccount = () => {
      // if (userStore.isSubAccount && isForbidenSubAccount(path)) {
      //   toast.error(i18n.global.t('此功能不支持子账户类型操作'));
      //   next({ path: '/' });
      // }
    };

    // 用户角色权限限制
    const filterUserRolePermission = async() => {
      if (!meta.userRolePermission) {
        return;
      }

      if (userStore.isAuthorizedAdmin) {
        return;
      }

      if (!userStore.hasUserRolePermission(meta.userRolePermission)) {
        next({ path: '/user/info' });
      }
    };

    // 跳转登录
    const toLogin = () => {
      if (import.meta.env.SSR) {
        return next();
      }
      const loginQuery = new URLSearchParams({ backurl: encodeURIComponent(decodeURIComponent(fullPath)), passport: 'hk' }).toString();
      // 跳转passport
      linkToPassport(`/login?${loginQuery}`);
    };

    // 获取用户登录态
    const login = async() => {
      // 并发获取用户基本信息和用户角色权限，耗时为二者较大值
      const [isLoginSuccess, _] = await Promise.all([
        userStore.getUserinfo(),
        userStore.getUserRolePermission()
      ]);

      // 如果是授权管理员，获取授权管理员权限
      if (userStore.isAuthorizedAdmin) {
        await userStore.getAuthorizedAdminPermission();
      }

      // custody用户第三方登录, 如果是已登录情况, 跳转到kyc页面再做判定
      if (isLoginSuccess && getOriginPath(path) === '/login' && to.query.oauthRequestId) {
        next({
          path: '/user/kyc',
          query: {
            redirectToCustody: 1
          }
        });
        return;
      }
      if (isLoginSuccess) {
        filterLoginPage();
        filterSubAccount();
        filterUserRolePermission();
        next();
      } else {
        // 强制登出
        userStore.forceLogOut(() => {
          // 去passport登录
          if (forbiddenRoute.includes(getOriginPath(path))) {
            // 跳转passport
            linkToPassport(`${path}?${urlQuery}`);
          } else {
            auth === 'login' ? toLogin : next();
          }
        });
      }
    };

    // 对于 www 域名 （本地调试除外）：
    // 如果存在 au_sign cookie，说明用户在 exchange 登录过，需要替换域名为 exchange，并保留所有参数
    // 否则，不进行任何操作
    if (!isHK() && getCookie('au_sign') && location.origin !== apiHost.webHKTest) {
      linkToExchange(path, query);
      return;
    }

    // 去passport登录
    if (forbiddenRoute.includes(getOriginPath(path)) && !userId && isPC()) {
      // 如果是预渲染直接往下运行
      if (import.meta.env.SSR) {
        return next();
      }
      // 跳转passport
      linkToPassport(`${path}?${urlQuery}`);
      return;
    }

    // 进入auth路由，并且有url中有fromWebSite参数，时需要判断是否有user_id
    if (getOriginPath(path) === '/auth' && query?.fromWebSite) {
      if (userId && accountId) {
        // 判断用户状态
        userStore.getUserinfo().then((res: any) => {
          if (res) {
            // 有登录态
            next({
              path: '/',
              query: {
                passport: 'hk'
              }
            });
          } else {
            // 清除本地的cookie
            removeCookie('account_id');
            removeCookie('user_id');
            // 无登录态，先跳转passport，由passport做重定向
            const authQuery = new URLSearchParams({ backurl: '/', passport: 'hk' }).toString();
            // 跳转passport
            linkToPassport(`/auth?${authQuery}`);
          }
        });
      } else {
        // 无登录态，先跳转passport，由passport做重定向
        const authQuery = new URLSearchParams({ backurl: '/', passport: 'hk' }).toString();
        // 跳转passport
        linkToPassport(`/auth?${authQuery}`);
      }
      return;
    }

    // 忽略登录态和用户信息
    if (auth === 'ignore') {
      return next();
    }

    if (userId) {
      if (userStore.isLogin) {
        filterLoginPage();
        filterSubAccount();
        filterUserRolePermission();
        next();
      } else {
        login();
      }
    } else if (auth === 'login') {
      toLogin();
    } else {
      next();
    }
  }]
};
